<template>
  <b-card>
    <b-row>
      <b-col sm="12" v-if="optionsLocal.approved && !optionsLocal.awaitingApproval"
        class="d-flex flex-column justify-content-around" pill style="padding: 7px; font-size: larger">
        <b-badge variant="success" pill style="padding: 5px; font-size: large">
          Aramıza Hoşgeldiniz. Diko'yu kullanmaya başlayabilirsiniz. &#128075;
        </b-badge>
        <!-- <h3>{{ $t("Degree") }}</h3> -->
        <!-- <p>{{ optionsLocal.degree }}</p> -->
      </b-col>
      <b-col sm="6" class="mb-1 d-flex justify-content-around" v-if="optionsLocal.approved">
      </b-col>
      <!-- alert -->
      <b-col cols="12" class="mt-75" v-if="!optionsLocal.emailApproved">
        <b-alert show variant="warning" class="mb-10">
          <h4 class="alert-heading" style="
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              align-content: center;
              align-items: center;
            ">
            {{ $t("Your email is not confirmed. Please check your inbox.") }}
            <br />
            <br />
            <!-- <div class="alert-body"> -->
            <b-link class="alert-link" @click="sendConfirmEmail">
              <b-button variant="info">
                {{ $t("Resend confirmation") }}
              </b-button>
            </b-link>
          </h4>
          <!-- </div> -->
        </b-alert>
      </b-col>
      <b-col md="12" class="d-flex flex-column justify-content-around text-center bg-info text-white rounded font-weight-bold mt-1 mb-1 p-1"  
        v-if="!optionsLocal.approved && !optionsLocal.awaitingApproval && !optionsLocal.rejectedApproval">
        <span style="font-size:18px">
          Hesap bilgilerinizin onaylanması için lütfen aşağıdaki bilgileri eksiksiz doldurunuz ve onaya gönder butonuna tıklayınız.
        </span>
      </b-col>
      <b-col md="12" class="d-flex flex-column justify-content-around text-center bg-danger text-white rounded font-weight-bold mt-1 mb-1 p-1"  
        v-if="optionsLocal.rejectedApproval">
        <span style="font-size:18px">
          Hesabınız malesef onaylanamadı. Lütfen e-posta adresinizi kontrol ediniz ve uyarıları dikkate alarak yeniden başvuru yapınız.
        </span>
      </b-col>
      <b-col sm="12" class="d-flex flex-column align-items-center" v-if="optionsLocal.awaitingApproval">
        <b-badge variant="warning" pill style="padding: 10px; font-size: large">
          Hesabınıza ait bilgiler onay sürecine alınmıştır en kısa süre içinde e-posta ile dönüş ve bilgilendirme yapılacaktır.
        </b-badge>
        <span style="margin-top: 10px">
          {{ $t("You Will Get One Program For Free On Approval") }}
        </span>
      </b-col>
    </b-row>
    <!-- media -->
    <b-media no-body class="mb-1">
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="
              optionsLocal
                ? optionsLocal.avatar
                  ? optionsLocal.avatar
                  : baseURL + '/images/dikologo.png'
                : baseURL + '/images/dikologo.png'
            " height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-card-text style="margin-bottom: 3px; margin-top: -8px">{{ $t("Panel Image")
          }}</b-card-text>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
          @click="fileinputclick">
          {{ $t("Upload") }}
        </b-button>
        <!--/ upload button -->
        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75"
          @click="resetImage">
          {{ $t("Revert") }}
        </b-button>
        <!--/ reset -->
        <b-card-text style="margin-top: -3px">{{ $t("Allowed JPG, GIF or PNG. Max size of 800kB")
          }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <!-- form -->
    <b-form class="mt-2 mb-1" ref="editProfileGeneralForm" autocomplete="off">
      <b-form-file ref="refInputEl" id="fileinput" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
        @input="inputImageRenderer" />
      <b-row>
        <!-- <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.username"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col sm="12">
          <b-form-group label-for="account-company">
            <b-form-input
              v-model="optionsLocal.company"
              name="company"
              placeholder="Company name"
            /> 
            <h3>{{ optionsLocal.company }}</h3>
          </b-form-group>
        </b-col> -->
        <b-col sm="6" class="mb-1">
          <b-form-group :label="$t('Full Name')" label-for="account-name">
            <b-form-input v-model="optionsLocal.name" name="name" :placeholder="$t('Full Name')" autocomplete="off" />
          </b-form-group>
        </b-col>
        <b-col sm="6" class="mb-1">
          <b-form-group :label="$t('E-Mail')" label-for="account-e-mail">
            <b-input-group>
              <b-form-input v-model="optionsLocal.email" name="email" :placeholder="$t('E-mail')" autocomplete="off"
                :formatter="emailFormatter" type="email" @blur="emailValidate($event)" @focus="
                  $event.target.classList.remove('is-invalid');
                  $event.target.placeholder = $t('E-mail');
                " required />
              <b-input-group-append>
                <b-button :variant="optionsLocal.emailApproved ? 'success' : 'danger'" class="no-cursor">
                  <feather-icon :icon="optionsLocal.emailApproved ? 'CheckIcon' : 'XIcon'" size="14" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="6" class="mb-1">
          <b-form-group :label="$t('Phone Number')" label-for="account-phone">
            <b-form-input name="phone" v-model="optionsLocal.phone" :placeholder="$t('Phone Number')" autocomplete="off"
              ref="phoneInput" :formatter="phoneFormatter" required minlength="10" maxlength="10" @blur="
                if ($event.target.value.length < 10) {
                  $event.target.classList.add('is-invalid');
                  $event.target.placeholder = $t('Minimum 10 digits');
                  $event.target.value = '';
                }
              " @focus="
                $event.target.classList.remove('is-invalid');
                $event.target.placeholder = $t('Phone Number');
              " />
          </b-form-group>
        </b-col>
        <b-col sm="6" class="mb-1">
          <b-form-group :label="$t('Referrer Code')" label-for="account-referrer">
            <b-input-group>
              <b-input-group-prepend v-if="optionsLocal.initialReferrer" v-b-tooltip.hover.v-primary
                :title="$t('Discount')">
                <b-input-group-text><strong
                    class="text-primary">{{ optionsLocal.referrerDiscount }}%</strong></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input name="referrer" v-model="optionsLocal.referrer" :placeholder="$t('Referrer Code')"
                :disabled="optionsLocal.initialReferrer" />
              <b-input-group-append v-if="optionsLocal.initialReferrer">
                <b-input-group-text><strong class="text-info">{{ optionsLocal.referrerName
                    }}</strong></b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" v-if="!optionsLocal.approved && !optionsLocal.awaitingApproval" class="form-group">
          <b-row style="align-items: center">
            <b-col sm="6">
              <!-- <b-form-group
                :label="$t('Identification Number')"
                label-for="account-identification"
              > -->
              <b-row style="
                  padding-left: 1rem;
                  padding-right: 1rem;
                  justify-content: space-between;
                ">
                <label>{{ $t("Identification Number") }}</label>
                <b-badge variant="primary" pill style="cursor: pointer" v-b-tooltip.hover.v-primary
                  :title="$t('Identification Number is necessary to send Invoices')"> ? </b-badge>
              </b-row>
              <b-form-input name="identification" v-model="optionsLocal.identificationNumber" required
                :formatter="identificationFormatter" :placeholder="$t('Identification Number')" @blur="
                  if ($event.target.value.length < 10) {
                    $event.target.classList.add('is-invalid');
                    $event.target.placeholder = $t('Minimum 10 digits');
                    $event.target.value = '';
                  }
                " @focus="
                  $event.target.classList.remove('is-invalid');
                  $event.target.placeholder = $t('Identification Number');
                " />
              <!-- </b-form-group> -->
            </b-col>
            <b-col sm="3">
              <b-row style="
                  padding-left: 1rem;
                  padding-right: 1rem;
                  justify-content: space-between;
                ">
                <label>{{ $t("Identification Visual") }}</label>
                <b-badge variant="primary" pill v-b-tooltip.hover.v-primary style="cursor: pointer" :title="
                    $t(
                      'Your Name and Identification Number must be clearly visible in the picture'
                    )
                  "> ? </b-badge>
              </b-row>
              <!-- <b-form-group
                :label="$t('Identification')"
                label-for="account-identification"
              > -->
              <b-form-file :id="$t('identificationFileInput')" v-model="identificationFile"
                :placeholder="$t('Identification')" accept=".jpg, .png, .pdf, .jpeg," ref="identificationFileInput">
              </b-form-file>
              <!-- </b-form-group
            > -->
            </b-col>
            <b-col sm="3" v-if="!optionsLocal.awaitingApproval && !optionsLocal.approved">
              <!-- add file input for degree -->
              <!-- <b-form-group :label="$t('Degree')" label-for="account-degree"> -->
              <b-row style="
                  padding-left: 1rem;
                  padding-right: 1rem;
                  justify-content: space-between;
                ">
                <label>{{ $t("Degree Or Equivalent Visual") }}</label>
                <b-badge variant="primary" v-b-tooltip.hover.v-primary pill style="cursor: pointer" :title="
                    $t(
                      'We need to verify your degree to verify you are qualified to use our platform'
                    )
                  "> ? </b-badge>
              </b-row>
              <b-form-file id="degreeFileInput" v-model="degreeFile" accept=".jpg, .png, .pdf, .jpeg,"
                ref="degreeFileInput" :placeholder="$t('Degree')">
              </b-form-file>
              <!-- </b-form-group> -->
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6" v-if="
            (optionsLocal.identification && optionsLocal.awaitingApproval) ||
            optionsLocal.approved
          " class="mb-1">
          <h3>{{ $t("Identification Number") }}</h3>
          <p>{{ obscureID(optionsLocal.identification) }}</p>
        </b-col>
        <b-col sm="6" class="mb-1 d-flex justify-content-around" v-if="optionsLocal.approved">
          <b-row>
            <b-col cols="6" class="d-flex align-items-center flex-column">
              <span>{{ $t("Your Referral Code") }}</span>
              <b-button v-b-tooltip.hover.v-primary :title="$t('Share')" variant="primary" pill
                @click="shareReferralCode">
                <FeatherIcon icon="ShareIcon" size="14" />
              </b-button>
            </b-col>
            <b-col cols="6" class="d-flex align-items-center flex-column">
              <span>{{ optionsLocal._id }}</span>
              <b-button variant="primary" pill @click="copyReferralCode" v-b-tooltip.hover.v-primary :title="$t('Copy')">
                <FeatherIcon icon="CopyIcon" size="14" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col sm="12" v-if="!optionsLocal.approved" class="form-group"> -->
        <!-- <b-row style="align-items: center"> -->
        <!-- </b-col> -->
        <!-- </b-row> -->
        <!-- <b-badge v-else class="btn btn-success">
            {{ $t("Approved") }}
          </b-badge> -->
        <!--/ alert -->
        <b-col cols="12" class="mb-1">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" @click="updateProfile"
            :disabled="
              this.optionsLocal.approved
                ? false
                : !(
                    this.optionsLocal.phone &&
                    this.optionsLocal.identificationNumber &&
                    this.optionsLocal.name &&
                    this.optionsLocal.email &&
                    this.degreeFile &&
                    this.identificationFile &&
                    !this.awaitingApproval
                  )
            " :title="
              optionsLocal.awaitingApproval
                ? $t('Cannot Modify While Waiting Approval')
                : optionsLocal.approved
                ? $t('Save')
                : $t('Send for Approval')
            ">
            {{ optionsLocal.approved ? $t("Save") : $t("Send for Approval") }}
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            {{ $t("Reset") }}
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
    <b-modal ref="sharePlatformsModal" hide-footer :title="$t('Share On Social Media')">
      <b-row style="justify-content: space-evenly">
        <ShareNetwork network="facebook" :url="`https://app.dikotr.com/register?referrer=${optionsLocal._id}`"
          :media="`https://app.dikotr.com/${shareImg}`" title="Diko Dikkat ve Konsantrasyon Terapisini Ücretsiz Deneyin!"
          hashtags="diko,dikotr"><b-button variant="facebook"><feather-icon size="24" icon="FacebookIcon" /></b-button>
        </ShareNetwork>
        <ShareNetwork network="whatsapp" :url="`https://app.dikotr.com/register?referrer=${optionsLocal._id}`"
          :media="`https://app.dikotr.com/${shareImg}`" title="Diko Dikkat ve Konsantrasyon Terapisini Ücretsiz Deneyin!"
          hashtags="diko,dikotr"><b-button variant="success"><b-img :src="whatsappLogo" /></b-button></ShareNetwork>
        <ShareNetwork network="twitter" :url="`https://app.dikotr.com/register?referrer=${optionsLocal._id}`"
          :media="`https://app.dikotr.com/${shareImg}`" title="Diko Dikkat ve Konsantrasyon Terapisini Ücretsiz Deneyin!"
          hashtags="diko,dikotr"><b-button variant="twitter"><feather-icon icon="TwitterIcon" size="24" /></b-button>
        </ShareNetwork>
      </b-row>
      <b-row style="margin-top: 10px">
        <a
          :href="`https://app.dikotr.com/register?referrer=${optionsLocal._id}`">https://app.dikotr.com/register?referrer={{ optionsLocal._id }}</a>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BLink,
  BImg,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroupText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

const {
  axiosRoutes: { user: path },
} = require("/G_CONFIG.js");
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    FeatherIcon,
    VBTooltip,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroupText,
  },

  // props: {
  //   generalData: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      profileFile: null,
      userData: {},
      degreeFile: null,
      identificationFile: null,
      resetUserImage: false,
      shareImg: require("@/assets/share.jpg"),
      whatsappLogo: require("@/assets/walogo.svg"),
      // optionsLocal: null /* JSON.parse(JSON.stringify(this.generalData)), */,
    };
  },
  // computed: {
  // changesSaveable() {
  // return (

  // );
  // },
  // },
  methods: {
    sendConfirmEmail() {
      axios
        .post("/auth/sendConfirmEmail", { email: this.optionsLocal.email })
        .then((res) => {
          this.$bvToast.toast(this.$t("Email Sent"), {
            title: this.$t("Successful"),
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nullFunc() {},
    phoneFormatter(phone) {
      // dont allow non numeric characters and remove beginning 0 limit to 10 digits (for now)
      phone = phone.replace(/[^\d]/g, "").replace(/^0/, "").slice(0, 10);

      // make the format look like +xxx (xxx) xxx xx xx xx or (xxx) xxx xx xx depending on the length
      if (phone.length > 10) {
        // phone = phone.replace(
        //   /^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/,
        //   "+$1 ($2) $3 $4 $5"
        // );
        phone = phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, "+$1 $2 $3 $4 $5");
      } else {
        phone = phone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "($1) $2 $3 $4");
      }
      return phone;
    },
    emailFormatter(email) {
      //enforce a valid email is entered
      email = email.replace(/[^a-zA-Z0-9@.]/g, "");
      return email;
    },
    emailValidate(event) {
      let email = event.target.value;
      if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
        event.target.value = "";
        event.target.placeholder = this.$t("Invalid Email");
        event.target.classList.add("is-invalid");
      }
    },
    fileinputclick() {
      // #fileinput click
      document.getElementById("fileinput").click();
    },
    shareReferralCode() {
      this.$refs.sharePlatformsModal.show();
      // if (navigator.share) {
      //   navigator
      //     .share({
      //       title: "Diko",
      //       text: "Diko Referral Code",
      //       url: this.optionsLocal._id,
      //     })
      //     .then(() => console.log("Successful share"))
      //     .catch((error) => console.log("Error sharing", error));
      // } else {
      //   console.log("Share not supported");
      // }
    },
    copyReferralCode() {
      const el = document.createElement("textarea");
      el.value = this.optionsLocal._id;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    resetImage() {
      console.log("resetImage");
      // console.log(this.profileFile);
      this.profileFile = null;
      // console.log(this.profileFile);
      this.$refs.previewEl.src = axios.defaults.baseURL + "/images/dikologo.png";
      this.avatar = axios.defaults.baseURL + "/images/dikologo.png";
      this.resetUserImage = true;
    },
    popupAccountApproval() {
      // this.$bvModal.show("modal-approve-account");
    },
    obscureID(id) {
      if (!id) return "";
      if (id.length > 4) return id.replace(/(?!(^..|.?$)).(?=.{2})/g, "*");
      else return id;
    },
    identificationFormatter(value) {
      // only numbers minimum 10 maximum 11 digits
      return value.replace(/[^\d]/g, "").slice(0, 11);
    },
    updateProfile() {
      let formData = new FormData();
      formData.append("avatarfile", this.$refs.refInputEl.files[0]);
      if (this.$refs.identificationFileInput)
        formData.append(
          "identificationFile",
          this.$refs.identificationFileInput.files[0]
        );
      if (this.$refs.degreeFileInput)
        formData.append("degreeFile", this.$refs.degreeFileInput.files[0]);
      formData.append(
        "phone",
        this.optionsLocal.phone
          .replace(/\s/g, "")
          .replace("+", "")
          .replace("(", "")
          .replace(")", "")
      );
      if (formData.get("phone").length < 10) {
        this.$refs.phoneInput.focus();
        this.$refs.phoneInput.blur();
        return;
      }
      formData.append("identification", this.optionsLocal.identificationNumber);
      formData.append("referrer", this.optionsLocal.referrer);
      formData.append("name", this.optionsLocal.name);
      formData.append("email", this.optionsLocal.email);
      formData.append("resetUserImage", this.resetUserImage);
      axios
        .patch(`${path.edit}/${this.optionsLocal._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          // console.log(res);
          localStorage.setItem("userAvatar", axios.defaults.baseURL + data.avatar);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  // mounted() {
  //   setInterval(() => {
  //     console.log(
  //       this.optionsLocal.phone &&
  //         this.optionsLocal.identificationNumber &&
  //         this.optionsLocal.name &&
  //         this.optionsLocal.email &&
  //         this.degreeFile &&
  //         this.identificationFile &&
  //         !this.awaitingApproval,
  //       this.optionsLocal.phone,
  //       this.optionsLocal.identificationNumber,
  //       this.optionsLocal.name,
  //       this.optionsLocal.email,
  //       this.degreeFile,
  //       this.identificationFile,
  //       !this.awaitingApproval
  //     );
  //   }, 1000);
  // },
  setup(props, refs) {
    // console.log("refs", refs);
    // console.log("props", props);
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const fileItself = ref(null);
    const optionsLocal = ref(null);
    // let userData = {};
    const previewElChange = (image) => {
      // refs.previewEl.src = image;
      // props.generalData.avatar = image;
      refs.refs.previewEl.src = image;
      // console.log(previewEl);
    };
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewElChange);
    axios.get(path.my).then(({ data }) => {
      // console.log(res.data);
      console.log(data);
      // refs.refs.optionsLocal = res.data;
      // userData = data;
      // this.userData.avatar = axios.defaults.baseURL + data.avatar;
      optionsLocal.value = data;
      optionsLocal.value.avatar = axios.defaults.baseURL + data.avatar;
      optionsLocal.value.identificationNumber = data.identification;
      if (data.referrer) {
        optionsLocal.value.initialReferrer = data.referrer;
        optionsLocal.value.referrerName = data.referrerName;
        if (data.referrerDiscount)
          optionsLocal.value.referrerDiscount = data.referrerDiscount;
        else optionsLocal.value.referrerDiscount = 0;
      }

      // optionsLocal.value.referrer = data.referrer._id;
    });
    const baseURL = axios.defaults.baseURL;
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
      fileItself,
      optionsLocal,
      baseURL,
      // userData,
    };
  },
  created() {},
};
</script>
<style scoped>
.is-invalid {
  border-color: #dc3545;
}

.no-cursor:hover {
  cursor: default !important;
  box-shadow: none !important;
}
</style>
