<template>
  <b-card>
    <b-table ref="sessionTable" class="position-relative" :items="referrals" responsive :fields="tableColumns" show-empty
      :empty-text="$t('No Records Found')">
      <template #cell(name)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
        </div>
      </template>
      <template #cell(active)="data">
        <div class="text-nowrap">
          <b-badge v-if="vibeCheck(data.item)" variant="success">{{ $t("Active")
            }}</b-badge>
          <b-badge v-else variant="danger">{{ $t("Inactive") }}</b-badge>
        </div>
      </template>
      <template #cell(percent)="data">
        <span class="align-text-top text-capitalize">{{ data.item.lastAppliedPackageDiscount / 10 }}%</span>
      </template>
      <div slot="empty" class="text-center">
        <span class="align-middle">{{ $t("No Records Found") }}</span>
      </div>
    </b-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BTable,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
const {
  axiosRoutes: { user: path },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableColumns: [
        { key: "name", label: this.$t("Name"), sortable: true },
        {
          key: "active",
          label: this.$t("Status"),
          sortable: true,
          // formatter: (value) => (value ? "Active" : "Inactive"),
        },
        {
          key: "percent",
          label: this.$t("Percent"),
          sortable: true,
        },
        // { key: "actions", label: this.$t("Disconnect"), sortable: false },
      ],
      referrals: [
        {
          // name: "Title",
          // approved: true,
          // lastSubjectOperation: new Date(),
          // lastPurchaseDiscountUpdate: new Date(),
          // lastAppliedPackageDiscount: 0,
          // updatedAt: new Date(),
          // actions: "Actions",
        },
      ],
    };
  },
  created() {
    this.getReferrals();
  },
  methods: {
    getReferrals() {
      // console.log("path.referrals :>> ", path.referrals);
      axios.get(path.referrals).then(({ data }) => {
        // console.log("referrals: ");
        // console.log(data);
        this.referrals = data;
        // console.log("object :>> ", this.sessionList);
      });
    },
    vibeCheck(item) {
      return (
        item.approved &&
        new Date(item.lastSubjectOperation)?.getTime() >=
          Date.now() - 1000 * 60 * 60 * 24 * 90 &&
        new Date(item.lastPurchaseDiscountUpdate)?.getTime() >=
          Date.now() - 1000 * 60 * 60 * 24 * 360
        // new Date(item.updatedAt)?.getTime() >= Date.now() + 1000 * 60 * 60 * 24 * 90
      );
    },
  },
};
</script>
