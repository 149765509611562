<template>
  <b-card>
    <b-table ref="sessionTable" class="position-relative" :items="sessionList" responsive :fields="tableColumns"
      show-empty :empty-text="$t('No Records Found')">
      <template #cell(title)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.title }}</span>
        </div>
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ IPPARSER(data.item.ip) }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
          <feather-icon icon="XIcon" size="18" />
        </b-button>
      </template>
      <div slot="empty" class="text-center">
        <span class="align-middle">{{ $t("No Records Found") }}</span>
      </div>
    </b-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
const {
  axiosRoutes: { session: sessionPath },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableColumns: [
        { key: "title", label: this.$t("Device"), sortable: true },
        { key: "ip", label: this.$t("IP"), sortable: true },
        { key: "actions", label: this.$t("Disconnect"), sortable: false },
      ],
      sessionList: [
        {
          title: "Title",
          description: "Description",
          // actions: "Actions",
        },
      ],
    };
  },
  created() {
    this.getSessions();
    // axios.get(sessionPath.get).then((res) => {
    //   this.sessionList = res.data;
    //   // console.log("object :>> ", this.sessionList);
    // });
  },
  methods: {
    disconnectSession(id) {
      axios.delete(sessionPath.delete + "/" + id).then((res) => {
        this.getSessions();
        // console.log("object :>> ", this.sessionList);
      });
    },
    getSessions() {
      axios.get(sessionPath.get).then((res) => {
        this.sessionList = res.data;
        // console.log("object :>> ", this.sessionList);
      });
    },
    IPPARSER(ip) {
      // return ip.replace("::ffff:", "");
      return ip;
    },
  },
};
</script>
