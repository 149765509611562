<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Account Information") }}</span>
      </template>
      <account-setting-general v-if="options.general" />
      <!-- :general-data="options.general" -->
    </b-tab>
    <!--/ general tab -->
    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="UsersIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Your Referrals") }}</span>
      </template>
      <account-setting-referrals />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Change Password") }}</span>
      </template>
      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->
    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="ZapIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Your Sessions") }}</span>
      </template>
      <account-setting-sessions />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Institutional Info") }}</span>
      </template>
      <account-setting-information v-if="options.info" />
    </b-tab>
    <!-- social links -->
    <!-- <b-tab> -->
    <!-- title -->
    <!-- <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Social") }}</span>
      </template>

      <account-setting-social v-if="options.social" :social-data="options.social" />
    </b-tab> -->
    <!-- notification -->
    <!-- <b-tab>
      title 
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("Notifications") }}</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingInformation from "./AccountSettingInformation.vue";
import AccountSettingSocial from "./AccountSettingSocial.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";
import AccountSettingSessions from "./AccountSettingSessions.vue";
import AccountSettingReferrals from "./AccountSettingReferrals.vue";
import accData from "@/@fake-db/data/pages/account-setting.js";
// import axios from "@axios";
import { $themeColors } from "@themeConfig";
// const {
//   axiosRoutes: { profile: path },
// } = require("/G_CONFIG");

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
    AccountSettingSessions,
    AccountSettingReferrals,
  },
  data() {
    return {
      options: {},
    };
  },
  beforeCreate() {
    // axios.get(`${path.getOne}/0`).then(({ data }) => {
    //   console.log(data);
    // });
    accData().then((data) => {
      this.options = data;
    });
    // this.options = accData;
    console.log(this.options);
  },
};
</script>
<style>
/* .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
} */
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Dosya Seç";
  color: #de39a5;
  /* background-color: rgb(22, 29, 49, 0.5); */
}
</style>
