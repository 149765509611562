var data = [
	{
		city: "Adana",
		plate: 1,
		district: [
			"Aladağ",
			"Ceyhan",
			"Çukurova",
			"Feke",
			"İmamoğlu",
			"Karaisalı",
			"Karataş",
			"Kozan",
			"Pozantı",
			"Saimbeyli",
			"Sarıçam",
			"Seyhan",
			"Tufanbeyli",
			"Yumurtalık",
			"Yüreğir",
		],
	},
	{
		city: "Adıyaman",
		plate: 2,
		district: [
			"Besni",
			"Çelikhan",
			"Gerger",
			"Gölbaşı",
			"Kahta",
			"Merkez",
			"Samsat",
			"Sincik",
			"Tut",
		],
	},
	{
		city: "Afyonkarahisar",
		plate: 3,
		district: [
			"Başmakçı",
			"Bayat",
			"Bolvadin",
			"Çay",
			"Çobanlar",
			"Dazkırı",
			"Dinar",
			"Emirdağ",
			"Evciler",
			"Hocalar",
			"İhsaniye",
			"İscehisar",
			"Kızılören",
			"Merkez",
			"Sandıklı",
			"Sinanpaşa",
			"Sultandağı",
			"Şuhut",
		],
	},
	{
		city: "Ağrı",
		plate: 4,
		district: [
			"Diyadin",
			"Doğubayazıt",
			"Eleşkirt",
			"Hamur",
			"Merkez",
			"Patnos",
			"Taşlıçay",
			"Tutak",
		],
	},
	{
		city: "Amasya",
		plate: 5,
		district: [
			"Göynücek",
			"Gümüşhacıköy",
			"Hamamözü",
			"Merkez",
			"Merzifon",
			"Suluova",
			"Taşova",
		],
	},
	{
		city: "Ankara",
		plate: 6,
		district: [
			"Altındağ",
			"Ayaş",
			"Bala",
			"Beypazarı",
			"Çamlıdere",
			"Çankaya",
			"Çubuk",
			"Elmadağ",
			"Güdül",
			"Haymana",
			"Kalecik",
			"Kızılcahamam",
			"Nallıhan",
			"Polatlı",
			"Şereflikoçhisar",
			"Yenimahalle",
			"Gölbaşı",
			"Keçiören",
			"Mamak",
			"Sincan",
			"Kazan",
			"Akyurt",
			"Etimesgut",
			"Evren",
			"Pursaklar",
		],
	},
	{
		city: "Antalya",
		plate: 7,
		district: [
			"Akseki",
			"Alanya",
			"Elmalı",
			"Finike",
			"Gazipaşa",
			"Gündoğmuş",
			"Kaş",
			"Korkuteli",
			"Kumluca",
			"Manavgat",
			"Serik",
			"Demre",
			"İbradı",
			"Kemer",
			"Aksu",
			"Döşemealtı",
			"Kepez",
			"Konyaaltı",
			"Muratpaşa",
		],
	},
	{
		city: "Artvin",
		plate: 8,
		district: [
			"Ardanuç",
			"Arhavi",
			"Merkez",
			"Borçka",
			"Hopa",
			"Şavşat",
			"Yusufeli",
			"Murgul",
		],
	},
	{
		city: "Aydın",
		plate: 9,
		district: [
			"Merkez",
			"Bozdoğan",
			"Efeler",
			"Çine",
			"Germencik",
			"Karacasu",
			"Koçarlı",
			"Kuşadası",
			"Kuyucak",
			"Nazilli",
			"Söke",
			"Sultanhisar",
			"Yenipazar",
			"Buharkent",
			"İncirliova",
			"Karpuzlu",
			"Köşk",
			"Didim",
		],
	},
	{
		city: "Balıkesir",
		plate: 10,
		district: [
			"Altıeylül",
			"Ayvalık",
			"Merkez",
			"Balya",
			"Bandırma",
			"Bigadiç",
			"Burhaniye",
			"Dursunbey",
			"Edremit",
			"Erdek",
			"Gönen",
			"Havran",
			"İvrindi",
			"Karesi",
			"Kepsut",
			"Manyas",
			"Savaştepe",
			"Sındırgı",
			"Gömeç",
			"Susurluk",
			"Marmara",
		],
	},
	{
		city: "Bilecik",
		plate: 11,
		district: [
			"Merkez",
			"Bozüyük",
			"Gölpazarı",
			"Osmaneli",
			"Pazaryeri",
			"Söğüt",
			"Yenipazar",
			"İnhisar",
		],
	},
	{
		city: "Bingöl",
		plate: 12,
		district: [
			"Merkez",
			"Genç",
			"Karlıova",
			"Kiğı",
			"Solhan",
			"Adaklı",
			"Yayladere",
			"Yedisu",
		],
	},
	{
		city: "Bitlis",
		plate: 13,
		district: [
			"Adilcevaz",
			"Ahlat",
			"Merkez",
			"Hizan",
			"Mutki",
			"Tatvan",
			"Güroymak",
		],
	},
	{
		city: "Bolu",
		plate: 14,
		district: [
			"Merkez",
			"Gerede",
			"Göynük",
			"Kıbrıscık",
			"Mengen",
			"Mudurnu",
			"Seben",
			"Dörtdivan",
			"Yeniçağa",
		],
	},
	{
		city: "Burdur",
		plate: 15,
		district: [
			"Ağlasun",
			"Bucak",
			"Merkez",
			"Gölhisar",
			"Tefenni",
			"Yeşilova",
			"Karamanlı",
			"Kemer",
			"Altınyayla",
			"Çavdır",
			"Çeltikçi",
		],
	},
	{
		city: "Bursa",
		plate: 16,
		district: [
			"Gemlik",
			"İnegöl",
			"İznik",
			"Karacabey",
			"Keles",
			"Mudanya",
			"Mustafakemalpaşa",
			"Orhaneli",
			"Orhangazi",
			"Yenişehir",
			"Büyükorhan",
			"Harmancık",
			"Nilüfer",
			"Osmangazi",
			"Yıldırım",
			"Gürsu",
			"Kestel",
		],
	},
	{
		city: "Çanakkale",
		plate: 17,
		district: [
			"Ayvacık",
			"Bayramiç",
			"Biga",
			"Bozcaada",
			"Çan",
			"Merkez",
			"Eceabat",
			"Ezine",
			"Gelibolu",
			"Gökçeada",
			"Lapseki",
			"Yenice",
		],
	},
	{
		city: "Çankırı",
		plate: 18,
		district: [
			"Merkez",
			"Çerkeş",
			"Eldivan",
			"Ilgaz",
			"Kurşunlu",
			"Orta",
			"Şabanözü",
			"Yapraklı",
			"Atkaracalar",
			"Kızılırmak",
			"Bayramören",
			"Korgun",
		],
	},
	{
		city: "Çorum",
		plate: 19,
		district: [
			"Alaca",
			"Bayat",
			"Merkez",
			"İskilip",
			"Kargı",
			"Mecitözü",
			"Ortaköy",
			"Osmancık",
			"Sungurlu",
			"Boğazkale",
			"Uğurludağ",
			"Dodurga",
			"Laçin",
			"Oğuzlar",
		],
	},
	{
		city: "Denizli",
		plate: 20,
		district: [
			"Acıpayam",
			"Buldan",
			"Çal",
			"Çameli",
			"Çardak",
			"Çivril",
			"Merkez",
			"Merkezefendi",
			"Pamukkale",
			"Güney",
			"Kale",
			"Sarayköy",
			"Tavas",
			"Babadağ",
			"Bekilli",
			"Honaz",
			"Serinhisar",
			"Baklan",
			"Beyağaç",
			"Bozkurt",
		],
	},
	{
		city: "Diyarbakır",
		plate: 21,
		district: [
			"Kocaköy",
			"Çermik",
			"Çınar",
			"Çüngüş",
			"Dicle",
			"Ergani",
			"Hani",
			"Hazro",
			"Kulp",
			"Lice",
			"Silvan",
			"Eğil",
			"Bağlar",
			"Kayapınar",
			"Sur",
			"Yenişehir",
			"Bismil",
		],
	},
	{
		city: "Edirne",
		plate: 22,
		district: [
			"Merkez",
			"Enez",
			"Havsa",
			"İpsala",
			"Keşan",
			"Lalapaşa",
			"Meriç",
			"Uzunköprü",
			"Süloğlu",
		],
	},
	{
		city: "Elazığ",
		plate: 23,
		district: [
			"Ağın",
			"Baskil",
			"Merkez",
			"Karakoçan",
			"Keban",
			"Maden",
			"Palu",
			"Sivrice",
			"Arıcak",
			"Kovancılar",
			"Alacakaya",
		],
	},
	{
		city: "Erzincan",
		plate: 24,
		district: [
			"Çayırlı",
			"Merkez",
			"İliç",
			"Kemah",
			"Kemaliye",
			"Refahiye",
			"Tercan",
			"Üzümlü",
			"Otlukbeli",
		],
	},
	{
		city: "Erzurum",
		plate: 25,
		district: [
			"Aşkale",
			"Çat",
			"Hınıs",
			"Horasan",
			"İspir",
			"Karayazı",
			"Narman",
			"Oltu",
			"Olur",
			"Pasinler",
			"Şenkaya",
			"Tekman",
			"Tortum",
			"Karaçoban",
			"Uzundere",
			"Pazaryolu",
			"Köprüköy",
			"Palandöken",
			"Yakutiye",
			"Aziziye",
		],
	},
	{
		city: "Eskişehir",
		plate: 26,
		district: [
			"Çifteler",
			"Mahmudiye",
			"Mihalıççık",
			"Sarıcakaya",
			"Seyitgazi",
			"Sivrihisar",
			"Alpu",
			"Beylikova",
			"İnönü",
			"Günyüzü",
			"Han",
			"Mihalgazi",
			"Odunpazarı",
			"Tepebaşı",
		],
	},
	{
		city: "Gaziantep",
		plate: 27,
		district: [
			"Araban",
			"İslahiye",
			"Nizip",
			"Oğuzeli",
			"Yavuzeli",
			"Şahinbey",
			"Şehitkamil",
			"Karkamış",
			"Nurdağı",
		],
	},
	{
		city: "Giresun",
		plate: 28,
		district: [
			"Alucra",
			"Bulancak",
			"Dereli",
			"Espiye",
			"Eynesil",
			"Merkez",
			"Görele",
			"Keşap",
			"Şebinkarahisar",
			"Tirebolu",
			"Piraziz",
			"Yağlıdere",
			"Çamoluk",
			"Çanakçı",
			"Doğankent",
			"Güce",
		],
	},
	{
		city: "Gümüşhane",
		plate: 29,
		district: ["Merkez", "Kelkit", "Şiran", "Torul", "Köse", "Kürtün"],
	},
	{
		city: "Hakkari",
		plate: 30,
		district: ["Çukurca", "Merkez", "Şemdinli", "Yüksekova"],
	},
	{
		city: "Hatay",
		plate: 31,
		district: [
			"Altınözü",
			"Arsuz",
			"Defne",
			"Dörtyol",
			"Hassa",
			"Antakya",
			"İskenderun",
			"Kırıkhan",
			"Payas",
			"Reyhanlı",
			"Samandağ",
			"Yayladağı",
			"Erzin",
			"Belen",
			"Kumlu",
		],
	},
	{
		city: "Isparta",
		plate: 32,
		district: [
			"Atabey",
			"Eğirdir",
			"Gelendost",
			"Merkez",
			"Keçiborlu",
			"Senirkent",
			"Sütçüler",
			"Şarkikaraağaç",
			"Uluborlu",
			"Yalvaç",
			"Aksu",
			"Gönen",
			"Yenişarbademli",
		],
	},
	{
		city: "Mersin",
		plate: 33,
		district: [
			"Anamur",
			"Erdemli",
			"Gülnar",
			"Mut",
			"Silifke",
			"Tarsus",
			"Aydıncık",
			"Bozyazı",
			"Çamlıyayla",
			"Akdeniz",
			"Mezitli",
			"Toroslar",
			"Yenişehir",
		],
	},
	{
		city: "İstanbul",
		plate: 34,
		district: [
			"Adalar",
			"Bakırköy",
			"Beşiktaş",
			"Beykoz",
			"Beyoğlu",
			"Çatalca",
			"Eyüp",
			"Fatih",
			"Gaziosmanpaşa",
			"Kadıköy",
			"Kartal",
			"Sarıyer",
			"Silivri",
			"Şile",
			"Şişli",
			"Üsküdar",
			"Zeytinburnu",
			"Büyükçekmece",
			"Kağıthane",
			"Küçükçekmece",
			"Pendik",
			"Ümraniye",
			"Bayrampaşa",
			"Avcılar",
			"Bağcılar",
			"Bahçelievler",
			"Güngören",
			"Maltepe",
			"Sultanbeyli",
			"Tuzla",
			"Esenler",
			"Arnavutköy",
			"Ataşehir",
			"Başakşehir",
			"Beylikdüzü",
			"Çekmeköy",
			"Esenyurt",
			"Sancaktepe",
			"Sultangazi",
		],
	},
	{
		city: "İzmir",
		plate: 35,
		district: [
			"Aliağa",
			"Bayındır",
			"Bergama",
			"Bornova",
			"Çeşme",
			"Dikili",
			"Foça",
			"Karaburun",
			"Karşıyaka",
			"Kemalpaşa",
			"Kınık",
			"Kiraz",
			"Menemen",
			"Ödemiş",
			"Seferihisar",
			"Selçuk",
			"Tire",
			"Torbalı",
			"Urla",
			"Beydağ",
			"Buca",
			"Konak",
			"Menderes",
			"Balçova",
			"Çiğli",
			"Gaziemir",
			"Narlıdere",
			"Güzelbahçe",
			"Bayraklı",
			"Karabağlar",
		],
	},
	{
		city: "Kars",
		plate: 36,
		district: [
			"Arpaçay",
			"Digor",
			"Kağızman",
			"Merkez",
			"Sarıkamış",
			"Selim",
			"Susuz",
			"Akyaka",
		],
	},
	{
		city: "Kastamonu",
		plate: 37,
		district: [
			"Abana",
			"Araç",
			"Azdavay",
			"Bozkurt",
			"Cide",
			"Çatalzeytin",
			"Daday",
			"Devrekani",
			"İnebolu",
			"Merkez",
			"Küre",
			"Taşköprü",
			"Tosya",
			"İhsangazi",
			"Pınarbaşı",
			"Şenpazar",
			"Ağlı",
			"Doğanyurt",
			"Hanönü",
			"Seydiler",
		],
	},
	{
		city: "Kayseri",
		plate: 38,
		district: [
			"Bünyan",
			"Develi",
			"Felahiye",
			"İncesu",
			"Pınarbaşı",
			"Sarıoğlan",
			"Sarız",
			"Tomarza",
			"Yahyalı",
			"Yeşilhisar",
			"Akkışla",
			"Talas",
			"Kocasinan",
			"Melikgazi",
			"Hacılar",
			"Özvatan",
		],
	},
	{
		city: "Kırklareli",
		plate: 39,
		district: [
			"Babaeski",
			"Demirköy",
			"Merkez",
			"Kofçaz",
			"Lüleburgaz",
			"Pehlivanköy",
			"Pınarhisar",
			"Vize",
		],
	},
	{
		city: "Kırşehir",
		plate: 40,
		district: [
			"Çiçekdağı",
			"Kaman",
			"Merkez",
			"Mucur",
			"Akpınar",
			"Akçakent",
			"Boztepe",
		],
	},
	{
		city: "Kocaeli",
		plate: 41,
		district: [
			"Gebze",
			"Gölcük",
			"Kandıra",
			"Karamürsel",
			"Körfez",
			"Derince",
			"Başiskele",
			"Çayırova",
			"Darıca",
			"Dilovası",
			"İzmit",
			"Kartepe",
		],
	},
	{
		city: "Konya",
		plate: 42,
		district: [
			"Akşehir",
			"Beyşehir",
			"Bozkır",
			"Cihanbeyli",
			"Çumra",
			"Doğanhisar",
			"Ereğli",
			"Hadim",
			"Ilgın",
			"Kadınhanı",
			"Karapınar",
			"Kulu",
			"Sarayönü",
			"Seydişehir",
			"Yunak",
			"Akören",
			"Altınekin",
			"Derebucak",
			"Hüyük",
			"Karatay",
			"Meram",
			"Selçuklu",
			"Taşkent",
			"Ahırlı",
			"Çeltik",
			"Derbent",
			"Emirgazi",
			"Güneysınır",
			"Halkapınar",
			"Tuzlukçu",
			"Yalıhüyük",
		],
	},
	{
		city: "Kütahya",
		plate: 43,
		district: [
			"Altıntaş",
			"Domaniç",
			"Emet",
			"Gediz",
			"Merkez",
			"Simav",
			"Tavşanlı",
			"Aslanapa",
			"Dumlupınar",
			"Hisarcık",
			"Şaphane",
			"Çavdarhisar",
			"Pazarlar",
		],
	},
	{
		city: "Malatya",
		plate: 44,
		district: [
			"Akçadağ",
			"Arapgir",
			"Arguvan",
			"Darende",
			"Doğanşehir",
			"Hekimhan",
			"Merkez",
			"Pütürge",
			"Yeşilyurt",
			"Battalgazi",
			"Doğanyol",
			"Kale",
			"Kuluncak",
			"Yazıhan",
		],
	},
	{
		city: "Manisa",
		plate: 45,
		district: [
			"Akhisar",
			"Alaşehir",
			"Demirci",
			"Gördes",
			"Kırkağaç",
			"Kula",
			"Merkez",
			"Salihli",
			"Sarıgöl",
			"Saruhanlı",
			"Selendi",
			"Soma",
			"Şehzadeler",
			"Yunusemre",
			"Turgutlu",
			"Ahmetli",
			"Gölmarmara",
			"Köprübaşı",
		],
	},
	{
		city: "Kahramanmaraş",
		plate: 46,
		district: [
			"Afşin",
			"Andırın",
			"Dulkadiroğlu",
			"Onikişubat",
			"Elbistan",
			"Göksun",
			"Merkez",
			"Pazarcık",
			"Türkoğlu",
			"Çağlayancerit",
			"Ekinözü",
			"Nurhak",
		],
	},
	{
		city: "Mardin",
		plate: 47,
		district: [
			"Derik",
			"Kızıltepe",
			"Artuklu",
			"Merkez",
			"Mazıdağı",
			"Midyat",
			"Nusaybin",
			"Ömerli",
			"Savur",
			"Dargeçit",
			"Yeşilli",
		],
	},
	{
		city: "Muğla",
		plate: 48,
		district: [
			"Bodrum",
			"Datça",
			"Fethiye",
			"Köyceğiz",
			"Marmaris",
			"Menteşe",
			"Milas",
			"Ula",
			"Yatağan",
			"Dalaman",
			"Seydikemer",
			"Ortaca",
			"Kavaklıdere",
		],
	},
	{
		city: "Muş",
		plate: 49,
		district: ["Bulanık", "Malazgirt", "Merkez", "Varto", "Hasköy", "Korkut"],
	},
	{
		city: "Nevşehir",
		plate: 50,
		district: [
			"Avanos",
			"Derinkuyu",
			"Gülşehir",
			"Hacıbektaş",
			"Kozaklı",
			"Merkez",
			"Ürgüp",
			"Acıgöl",
		],
	},
	{
		city: "Niğde",
		plate: 51,
		district: ["Bor", "Çamardı", "Merkez", "Ulukışla", "Altunhisar", "Çiftlik"],
	},
	{
		city: "Ordu",
		plate: 52,
		district: [
			"Akkuş",
			"Altınordu",
			"Aybastı",
			"Fatsa",
			"Gölköy",
			"Korgan",
			"Kumru",
			"Mesudiye",
			"Perşembe",
			"Ulubey",
			"Ünye",
			"Gülyalı",
			"Gürgentepe",
			"Çamaş",
			"Çatalpınar",
			"Çaybaşı",
			"İkizce",
			"Kabadüz",
			"Kabataş",
		],
	},
	{
		city: "Rize",
		plate: 53,
		district: [
			"Ardeşen",
			"Çamlıhemşin",
			"Çayeli",
			"Fındıklı",
			"İkizdere",
			"Kalkandere",
			"Pazar",
			"Merkez",
			"Güneysu",
			"Derepazarı",
			"Hemşin",
			"İyidere",
		],
	},
	{
		city: "Sakarya",
		plate: 54,
		district: [
			"Akyazı",
			"Geyve",
			"Hendek",
			"Karasu",
			"Kaynarca",
			"Sapanca",
			"Kocaali",
			"Pamukova",
			"Taraklı",
			"Ferizli",
			"Karapürçek",
			"Söğütlü",
			"Adapazarı",
			"Arifiye",
			"Erenler",
			"Serdivan",
		],
	},
	{
		city: "Samsun",
		plate: 55,
		district: [
			"Alaçam",
			"Bafra",
			"Çarşamba",
			"Havza",
			"Kavak",
			"Ladik",
			"Terme",
			"Vezirköprü",
			"Asarcık",
			"Ondokuzmayıs",
			"Salıpazarı",
			"Tekkeköy",
			"Ayvacık",
			"Yakakent",
			"Atakum",
			"Canik",
			"İlkadım",
		],
	},
	{
		city: "Siirt",
		plate: 56,
		district: [
			"Baykan",
			"Eruh",
			"Kurtalan",
			"Pervari",
			"Merkez",
			"Şirvan",
			"Tillo",
		],
	},
	{
		city: "Sinop",
		plate: 57,
		district: [
			"Ayancık",
			"Boyabat",
			"Durağan",
			"Erfelek",
			"Gerze",
			"Merkez",
			"Türkeli",
			"Dikmen",
			"Saraydüzü",
		],
	},
	{
		city: "Sivas",
		plate: 58,
		district: [
			"Divriği",
			"Gemerek",
			"Gürün",
			"Hafik",
			"İmranlı",
			"Kangal",
			"Koyulhisar",
			"Merkez",
			"Suşehri",
			"Şarkışla",
			"Yıldızeli",
			"Zara",
			"Akıncılar",
			"Altınyayla",
			"Doğanşar",
			"Gölova",
			"Ulaş",
		],
	},
	{
		city: "Tekirdağ",
		plate: 59,
		district: [
			"Çerkezköy",
			"Çorlu",
			"Ergene",
			"Hayrabolu",
			"Malkara",
			"Muratlı",
			"Saray",
			"Süleymanpaşa",
			"Kapaklı",
			"Şarköy",
			"Marmaraereğlisi",
		],
	},
	{
		city: "Tokat",
		plate: 60,
		district: [
			"Almus",
			"Artova",
			"Erbaa",
			"Niksar",
			"Reşadiye",
			"Merkez",
			"Turhal",
			"Zile",
			"Pazar",
			"Yeşilyurt",
			"Başçiftlik",
			"Sulusaray",
		],
	},
	{
		city: "Trabzon",
		plate: 61,
		district: [
			"Akçaabat",
			"Araklı",
			"Arsin",
			"Çaykara",
			"Maçka",
			"Of",
			"Ortahisar",
			"Sürmene",
			"Tonya",
			"Vakfıkebir",
			"Yomra",
			"Beşikdüzü",
			"Şalpazarı",
			"Çarşıbaşı",
			"Dernekpazarı",
			"Düzköy",
			"Hayrat",
			"Köprübaşı",
		],
	},
	{
		city: "Tunceli",
		plate: 62,
		district: [
			"Çemişgezek",
			"Hozat",
			"Mazgirt",
			"Nazımiye",
			"Ovacık",
			"Pertek",
			"Pülümür",
			"Merkez",
		],
	},
	{
		city: "Şanlıurfa",
		plate: 63,
		district: [
			"Akçakale",
			"Birecik",
			"Bozova",
			"Ceylanpınar",
			"Eyyübiye",
			"Halfeti",
			"Haliliye",
			"Hilvan",
			"Karaköprü",
			"Siverek",
			"Suruç",
			"Viranşehir",
			"Harran",
		],
	},
	{
		city: "Uşak",
		plate: 64,
		district: ["Banaz", "Eşme", "Karahallı", "Sivaslı", "Ulubey", "Merkez"],
	},
	{
		city: "Van",
		plate: 65,
		district: [
			"Başkale",
			"Çatak",
			"Erciş",
			"Gevaş",
			"Gürpınar",
			"İpekyolu",
			"Muradiye",
			"Özalp",
			"Tuşba",
			"Bahçesaray",
			"Çaldıran",
			"Edremit",
			"Saray",
		],
	},
	{
		city: "Yozgat",
		plate: 66,
		district: [
			"Akdağmadeni",
			"Boğazlıyan",
			"Çayıralan",
			"Çekerek",
			"Sarıkaya",
			"Sorgun",
			"Şefaatli",
			"Yerköy",
			"Merkez",
			"Aydıncık",
			"Çandır",
			"Kadışehri",
			"Saraykent",
			"Yenifakılı",
		],
	},
	{
		city: "Zonguldak",
		plate: 67,
		district: ["Çaycuma", "Devrek", "Ereğli", "Merkez", "Alaplı", "Gökçebey"],
	},
	{
		city: "Aksaray",
		plate: 68,
		district: [
			"Ağaçören",
			"Eskil",
			"Gülağaç",
			"Güzelyurt",
			"Merkez",
			"Ortaköy",
			"Sarıyahşi",
		],
	},
	{
		city: "Bayburt",
		plate: 69,
		district: ["Merkez", "Aydıntepe", "Demirözü"],
	},
	{
		city: "Karaman",
		plate: 70,
		district: [
			"Ermenek",
			"Merkez",
			"Ayrancı",
			"Kazımkarabekir",
			"Başyayla",
			"Sarıveliler",
		],
	},
	{
		city: "Kırıkkale",
		plate: 71,
		district: [
			"Delice",
			"Keskin",
			"Merkez",
			"Sulakyurt",
			"Bahşili",
			"Balışeyh",
			"Çelebi",
			"Karakeçili",
			"Yahşihan",
		],
	},
	{
		city: "Batman",
		plate: 72,
		district: ["Merkez", "Beşiri", "Gercüş", "Kozluk", "Sason", "Hasankeyf"],
	},
	{
		city: "Şırnak",
		plate: 73,
		district: [
			"Beytüşşebap",
			"Cizre",
			"İdil",
			"Silopi",
			"Merkez",
			"Uludere",
			"Güçlükonak",
		],
	},
	{
		city: "Bartın",
		plate: 74,
		district: ["Merkez", "Kurucaşile", "Ulus", "Amasra"],
	},
	{
		city: "Ardahan",
		plate: 75,
		district: ["Merkez", "Çıldır", "Göle", "Hanak", "Posof", "Damal"],
	},
	{
		city: "Iğdır",
		plate: 76,
		district: ["Aralık", "Merkez", "Tuzluca", "Karakoyunlu"],
	},
	{
		city: "Yalova",
		plate: 77,
		district: [
			"Merkez",
			"Altınova",
			"Armutlu",
			"Çınarcık",
			"Çiftlikköy",
			"Termal",
		],
	},
	{
		city: "Karabük",
		plate: 78,
		district: [
			"Eflani",
			"Eskipazar",
			"Merkez",
			"Ovacık",
			"Safranbolu",
			"Yenice",
		],
	},
	{
		city: "Kilis",
		plate: 79,
		district: ["Merkez", "Elbeyli", "Musabeyli", "Polateli"],
	},
	{
		city: "Osmaniye",
		plate: 80,
		district: [
			"Bahçe",
			"Kadirli",
			"Merkez",
			"Düziçi",
			"Hasanbeyli",
			"Sumbas",
			"Toprakkale",
		],
	},
	{
		city: "Düzce",
		plate: 81,
		district: [
			"Akçakoca",
			"Merkez",
			"Yığılca",
			"Cumayeri",
			"Gölyaka",
			"Çilimli",
			"Gümüşova",
			"Kaynaşlı",
		],
	},
];

export function getCities(country) {
	if (country !== "Turkey") return [];
	return data.map(item => item.city);
}
export function getDistricts(city) {
	return data.find(item => item.city === city).district;
}
