<template>
  <b-card>
    <!-- form -->
    <!-- 
     of: {
		type: mongoose.Schema.Types.ObjectId,
		ref: "User",
	},
	firmName: {
		type: String,
		default: null,
	},
	address: {
		type: String,
		default: null,
	},
	city: {
		type: String,
		default: null,
	},
	district: {
		type: String,
		default: null,
	},
	zip: {
		type: String,
		default: null,
	},
	phone: {
		type: String,
		default: null,
	},
	fax: {
		type: String,
		default: null,
	},
	email: {
		type: String,
		default: null,
	},
	website: {
		type: String,
		default: null,
	},
	logo: {
		type: String,
		default: null,
	},
	about: {
		type: String,
		default: null,
	},
	services: {
		type: String,
		default: null,
	},
	specialty: {
		type: String,
		default: null,
	},
	awards: {
		type: String,
		default: null,
	},
	affiliations: {
		type: String,
		default: null,
	},
	certificates: [
		{
			name: String,
			path: String,
			date: Date,
		},
	],
	socialLinks: {
		facebook: String,
		twitter: String,
		instagram: String,
		linkedin: String,
	},
    -->
    <b-form @submit.prevent="updateInformation" v-if="informationData ? informationData.userApproved : false">
      <b-card>
        <b-row class="mb-3 align-content-center align-items-center">
          <b-col>
            <b-button variant="outline-primary" v-b-tooltip.hover="$t('Load From Personal Information')"
              @click="fillTheData()" type="button" role="button">
              <FeatherIcon icon="ChevronsDownIcon"></FeatherIcon>
            </b-button>
          </b-col>
          <b-col sm="11" class="information-text-wrapper">
            <p class="information-text">
              {{ $t(
                    "The information you enter here will be used for your promotion in the Application Centers section of our website."
                  )
                }}
            </p>
          </b-col>
        </b-row>
        <b-row style="align-items: flex-end">
          <b-col sm="6">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img ref="previewLogoEl" rounded :src="informationData.logo" height="80" />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>
              <b-media-body class="mt-75 ml-75">
                <b-card-text style="margin-bottom: 3px; margin-top: -8px">{{ $t("Commercial Image")
                  }}</b-card-text>
                <!-- upload button -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                  @click="logoInputClick">
                  {{ $t("Upload") }}
                </b-button>
                <!--/ upload button -->
                <!-- reset -->
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="resetImage"
                > -->
                <!-- {{ $t("Reset") }}
                </b-button> -->
                <!--/ reset -->
                <b-card-text>{{ $t("Allowed JPG, GIF or PNG. Max size of 800kB")
                  }}</b-card-text>
              </b-media-body>
            </b-media></b-col>
          <b-form-file id="logoInput" :hidden="true" plain v-model="logoFile" name="logo" ref="refInputLogo"
            :placeholder="$t('Logo')" @input="inputImageRenderer" />
          <b-col sm="6">
            <b-form-group :label="$t('Firm Name')" label-for="firmName">
              <b-form-input id="firmName" v-model="informationData.firmName" name="firmName"
                :placeholder="$t('Firm Name')" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card :title="$t('Contact Information')" class="mt-1" style="width: 100%">
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('Phone Number')" label-for="phone">
              <b-form-input id="phone" v-model="informationData.phone" name="phone" :placeholder="$t('Phone Number')"
                :formatter="phoneFormatter" minlength="10" maxlength="10" @blur="
                  if ($event.target.value.length < 10) {
                    $event.target.classList.add('is-invalid');
                    $event.target.placeholder = $t('Minimum 10 digits');
                    $event.target.value = '';
                  }
                " @focus="
                  $event.target.classList.remove('is-invalid');
                  $event.target.placeholder = $t('Phone Number');
                " />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Email')" label-for="email">
              <b-form-input id="email" v-model="informationData.email" name="email" :placeholder="$t('Email')" />
            </b-form-group>
          </b-col>
          <!-- <b-col sm="6">
            <b-form-group :label="$t('Fax')" label-for="fax">
              <b-form-input id="fax" v-model="informationData.fax" name="fax" :placeholder="$t('Fax')" />
            </b-form-group>
          </b-col> -->
          <b-col sm="6">
            <b-form-group :label="$t('Website')" label-for="website">
              <b-form-input id="website" v-model="informationData.website" name="website" :placeholder="$t('Website')" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card :title="$t('Location Information')" class="mt-1" style="width: 100%">
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('Country')" label-for="country">
              <b-form-select id="Country" v-model="informationData.country" name="country" :options="
                  countries.map((country) => {
                    return {
                      value: country,
                      text: $t(country),
                    };
                  })
                " :placeholder="$t('Country')" @change="countrySelected" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('City')" label-for="city">
              <b-form-select id="city" v-model="informationData.city" name="city" :options="cities"
                :placeholder="$t('City')" @change="citySelected" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Zip Code')" label-for="zip">
              <b-form-input id="zip" v-model="informationData.zip" name="zip" :placeholder="$t('Zip Code')" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('District')" label-for="district">
              <b-form-select id="district" v-model="informationData.district" name="district" :options="districts"
                :placeholder="$t('District')" />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group :label="$t('Address')" label-for="address">
              <b-form-input id="address" v-model="informationData.address" name="address" :placeholder="$t('Address')" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card style="width: 100%" class="mt-1" :title="$t('About You')">
        <b-row>
          <b-col sm="12">
            <b-form-group>
              <b-form-textarea id="bio" name="about" v-model="informationData.about" :placeholder="$t('Bio')">
              </b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Specialty')" label-for="specialty">
              <b-form-input id="specialty" v-model="informationData.specialty" name="specialty"
                :placeholder="$t('Your Specialty')" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Services')" label-for="services">
              <b-form-input id="services" v-model="informationData.services" name="services"
                :placeholder="$t('Services You Offer')" />
            </b-form-group>
          </b-col>
          <!-- <b-col sm="6">
            <b-form-group :label="$t('Awards')" label-for="awards">
              <b-form-input id="awards" v-model="informationData.awards" name="awards" :placeholder="$t('Awards')" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Affiliations')" label-for="affiliations">
              <b-form-input id="affiliations" v-model="informationData.affiliations" name="affiliations"
                :placeholder="$t('Your Affiliations')" />
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-card>
      <b-card><b-row><b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="LinkIcon" size="18" />
              <h4 class="mb-0 ml-75">
                {{ $t("Social Links") }}
              </h4>
            </div>
          </b-col>
          <!-- twitter -->
          <b-col md="6">
            <b-form-group label-for="account-twitter" label="Twitter">
              <b-form-input id="account-twitter" v-model="informationData.socialLinks.twitter"
                :placeholder="$t('Add link')" />
            </b-form-group>
          </b-col>
          <!--/ twitter -->
          <!-- facebook -->
          <b-col md="6">
            <b-form-group label-for="account-facebook" label="Facebook">
              <b-form-input id="account-facebook" v-model="informationData.socialLinks.facebook"
                :placeholder="$t('Add link')" />
            </b-form-group>
          </b-col>
          <!--/ facebook -->
          <!-- google+ -->
          <!-- <b-col md="6">
            <b-form-group label-for="account-google" label="Google+">
              <b-form-input id="account-google" v-model="informationData.socialLinks.google"
                :placeholder="$t('Add link')" />
            </b-form-group>
          </b-col> -->
          <!--/ google+ -->
          <!-- linkedin -->
          <b-col md="6">
            <b-form-group label-for="account-linkedin" label="LinkedIn">
              <b-form-input id="account-linkedin" v-model="informationData.socialLinks.linkedin"
                :placeholder="$t('Add link')" />
            </b-form-group>
          </b-col>
          <!-- linkedin -->
          <!-- instagram -->
          <b-col md="6">
            <b-form-group label="Instagram" label-for="account-instagram">
              <b-form-input id="account-instagram" v-model="informationData.socialLinks.instagram"
                :placeholder="$t('Add link')" />
            </b-form-group>
          </b-col>
          <!--/ instagram -->
          <!-- quora -->
          <!-- <b-col md="6">
            <b-form-group label-for="account-quora" label="Quora">
              <b-form-input id="account-quora" v-model="informationData.socialLinks.quora"
                :placeholder="$t('Add link')" />
            </b-form-group> </b-col> -->
        </b-row></b-card>
      <b-col cols="12">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
          @click.prevent="updateInformation">
          {{ $t("Save") }}
        </b-button>
      </b-col>
    </b-form>
    <span v-else> {{ $t("Your account has to be approved first") }}</span>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BCardText,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import axios from "@axios";
import { ref } from "@vue/composition-api";
import { getCities, getDistricts } from "./turkey.js";

const {
  axiosRoutes: { profile: path },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormSelect,
    BLink,
    BRow,
    BImg,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    BFormFile,
    flatPickr,
    Cleave,
    BCardText,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      countries: ["Turkey"],
      logoFile: null,
      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
    };
  },
  setup(props, { refs }) {
    const informationData = ref(null);
    const fillData = ref({});
    const refInputLogo = ref(null);
    const previewLogoEl = ref(null);
    const cities = ref([]);
    const districts = ref([]);
    // let userData = {};
    console.log("refs");
    console.log(refs);
    const previewLogoChange = (image) => {
      // refs.previewEl.src = image;
      // props.generalData.avatar = image;
      refs.previewLogoEl.src = image;
      // console.log(previewEl);
    };
    const { inputImageRenderer } = useInputImageRenderer(refInputLogo, previewLogoChange);
    axios.get(path.my).then(({ data }) => {
      informationData.value = data.profile;
      if (data.profile.country) {
        cities.value = getCities(data.profile.country);
        informationData.value.country = data.profile.country;
      } else {
        cities.value = getCities("Turkey");
        informationData.value.country = "Turkey";
      }
      if (data.profile.city) districts.value = getDistricts(data.profile.city);
      if (!data.profile.logo) {
        fillData.value.logo = axios.defaults.baseURL + data.user.avatar;
        informationData.value.logo = "";
      } else informationData.value.logo = axios.defaults.baseURL + data.profile.logo;
      if (!data.profile.email) {
        fillData.value.email = data.user.email;
        informationData.value.email = "";
      }
      if (!data.profile.phone) {
        fillData.value.phone = data.user.phone;
        informationData.value.phone = "";
      }
      if (!data.profile.firmName) {
        fillData.value.firmName = data.user.name;
        informationData.value.firmName = "";
      }
      informationData.value.userApproved = data.user.approved;
      console.log(data.user);
      // console.log(informationData.value);
      // previewLogoEl.src = informationData.value.logo;
    });
    return {
      informationData,
      inputImageRenderer,
      refInputLogo,
      previewLogoEl,
      cities,
      districts,
      fillData,
    };
  },
  methods: {
    resetForm() {
      // this.informationData = JSON.parse(JSON.stringify(this.informationData));
    },
    countrySelected() {
      this.cities = getCities(this.informationData.country);
    },
    citySelected() {
      this.districts = getDistricts(this.informationData.city);
    },
    logoInputClick() {
      // #fileinput click
      document.getElementById("logoInput").click();
    },
    fillTheData() {
      console.log(this.fillData);
      console.log(this.informationData);
      this.informationData = {
        ...this.informationData,
        ...this.fillData,
      };
      console.log(this.informationData);
    },
    phoneFormatter(phone) {
      // dont allow non numeric characters and remove beginning 0 limit to 10 digits (for now)
      phone = phone.replace(/[^\d]/g, "").replace(/^0/, "").slice(0, 10);

      // make the format look like +xxx (xxx) xxx xx xx xx or (xxx) xxx xx xx depending on the length
      if (phone.length > 10) {
        // phone = phone.replace(
        //   /^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/,
        //   "+$1 ($2) $3 $4 $5"
        // );
        phone = phone.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, "+$1 $2 $3 $4 $5");
      } else {
        phone = phone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "($1) $2 $3 $4");
      }
      return phone;
    },
    updateInformation() {
      //if exists add logoFile to form data
      const formData = new FormData();
      if (this.logoFile) formData.append("logo", this.logoFile);
      formData.append("firmName", this.informationData.firmName);
      formData.append(
        "phone",
        this.informationData.phone
          .replace(/\s/g, "")
          .replace("+", "")
          .replace("(", "")
          .replace(")", "")
      );
      // formData.append("fax", this.informationData.fax);
      formData.append("email", this.informationData.email);
      formData.append("website", this.informationData.website);
      formData.append("address", this.informationData.address);
      formData.append("country", this.informationData.country);
      formData.append("city", this.informationData.city);
      formData.append("district", this.informationData.district);
      formData.append("zip", this.informationData.zip);
      formData.append("about", this.informationData.about);
      formData.append("services", this.informationData.services);
      formData.append("specialty", this.informationData.specialty);
      formData.append("awards", this.informationData.awards);
      formData.append("affiliations", this.informationData.affiliations);
      formData.append("socialLinks", JSON.stringify(this.informationData.socialLinks));

      axios
        .put(path.edit + "/" + this.informationData._id, formData)
        .then(({ data }) => {
          this.$swal({
            title: this.$t("Successful"),
            text: this.$t("Information Sent For Approval"),
            icon: "success",
            timer: 2000,
            buttons: false,
          }); /* .then(() => {

        }); */
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.information-text {
  font-weight: bolder;
  font-size: 1.1rem;
  margin: 0;
  border: 1px solid rgb(170, 170, 170, 0.5);
  border-radius: 10px;
  /* margin-bottom: 20px; */
  padding: 10px;
}
</style>
